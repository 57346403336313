<template>
  <b-form @submit.prevent="destroy">
    <div class="title-actions">
      <h5 class="title">{{$t('sliderPrimary')}} - {{$t('delete')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <img v-if="imgPath" :src="imgPath" :alt="slider.name" class="img-thumbnail mt-3">
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label for="title">{{$t('title')}}</label>
        <b-form-input id="title" v-model="slider.title" readonly  />
      </div>
      <div class="form-group col-12 col-md-9">
        <label for="url">Url</label>
        <b-form-input id="url" v-model="slider.url" readonly />
      </div>
      <div class="form-group col-12 col-md-3">
        <label for="position">{{$t('position')}}</label>
        <b-form-input type="number" id="position" v-model="slider.position" readonly />
      </div>
    </div>
    <b-button type="submit" variant="danger">{{$t('delete')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      imgPath: '',
      title: '',
      console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'sliders',
      'slider'
    ])
  },
  mounted() {
    const sliderId = this.$route.params.sliderId
    if (!sliderId) {
      this.$toastr.error(this.$t('error404'), window.TITLE_ERROR)
      this.$router.push({ name: 'Slider', params: { lang: this.lang } })
      return
    } else {
      this.imgPath = this.slider.image
    }
    if (this.sliders.length < 1) {
      this.$router.push({ name: 'Slider', params: { lang: this.lang } })
    }
  },
  methods: {
    destroy () {
      const sliderId = this.$route.params.sliderId
      if (!sliderId) {
        this.$toastr.error(this.$t('error404'), window.TITLE_ERROR)
        this.$router.push({ name: 'Slider', params: { lang: this.lang } })
      } else {
        const path = `auth/${this.lang}/${this.console}/slider/${sliderId}/delete`
        this.$axios.delete(path).then(() => {
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          this.$store.dispatch('DELETE_SLIDER', sliderId)
          this.$router.push({ name: 'Slider', params: { lang: this.lang } })
        })
      }
    }
  }
}
</script>
